<template>
  <div class="product">
    <div class="page-title">{{ productTitle }}</div>
    <div v-if="specList.length>0" class="screen-list">
      <div v-for="item in specList" :key="item.id" class="item">
        <div class="head">{{ item.name }}</div>
        <div class="list">
          <template v-for="(it, idx) in item.spec_values">
            <div v-if="item.openStatus?true:idx<8" :key="it.id" class="it" :class="{selected: it.selected}" @click="goSearch(it, item)">
              {{ it.value }}
              <span v-if="it.selected" class="span"><van-icon name="success" /></span>
            </div>
          </template>
        </div>
        <div v-if="item.spec_values.length>8" class="more">
          <span v-if="item.openStatus" @click="openMore(item)">收起<van-icon name="arrow-up" /></span>
          <span v-else class="btn" @click="openMore(item)">显示更多<van-icon name="arrow-down" /></span>
        </div>
      </div>
    </div>
    <div v-if="productList.length>0" class="product-list">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="getProductList"
      >
        <div class="product-vant-list">
          <div v-for="item in productList" :key="item.id" class="item" @click="goDetail(item.id)">
            <div class="pic">
              <img v-lazy="item.img_url" :src="item.img_url">
            </div>
            <div class="more">查看详情</div>
            <div class="title">{{ item.name }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div v-else style="padding-top: 20px; font-size: 14px; text-align: center; color: #aaa;">暂无数据</div>
  </div>
</template>

<script>
import { getList } from '@/api/index'
import pic from '@/assets/1.png'
export default {
  data() {
    return {
      pic,
      loading: false,
      finished: false,
      cateId: null,
      productTitle: '',
      specList: [],
      productList: [],
      page: 1
    }
  },
  watch: {
    '$route': {
      handler() {
        this.cateId = this.$route.query.cateId
        this.page = 1
        this.finished = false
        this.loading = false
        this.productList = []
        this.getProductList()
        this.getSpecList()
      },
      immediate: true
    }
  },
  methods: {
    goSearch(it, item) {
      item.spec_values.map(it => {
        this.$set(it, 'selected', false)
      })
      this.$set(it, 'selected', true)
      this.page = 1
      this.finished = false
      this.loading = false
      this.productList = []
      this.getProductList()
    },
    openMore(item) {
      item.openStatus = !item.openStatus
    },
    getSpecList() {
      const menuList = this.$store.state.menuList
      menuList.map(item => {
        if (this.cateId * 1 === item.id) {
          this.productTitle = item.name
          this.specList = item.product_category_spec
        }
      })
      this.specList.map(item => {
        this.$set(item, 'openStatus', false)
      })
    },
    async getProductList() {
      console.log(111, 22)
      const spec = []
      this.specList.map(item => {
        item.spec_values.map(it => {
          if (it.selected) {
            spec.push(it.id)
          }
        })
      })
      const res = await getList('product', { cate_id: this.cateId, spec: spec.join(','), page: this.page })
      this.loading = false
      if (res.data.length === 0) {
        this.finished = true
        return
      }
      this.productList = [...this.productList, ...res.data]
      this.page++
    },
    goDetail(pageId) {
      this.$router.push({ path: '/productDetail', query: { pageId }})
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  padding: 0 15px;
  .page-title {
    padding: 15px 0;
    font-size: 16px;
  }
  .screen-list {
    padding-bottom: 15px;
    background-color: #1A1A1A;
    .more {
      text-align: right;
      font-size: 10px;
      color: #fafafa;
      padding-right:  18px;
    }
    .head {
      font-size: 14px;
      padding: 15px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 15px;
      .it {
        position: relative;
        width: 70px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        margin-bottom: 15px;
        font-size: 10px;
        color: #333333;
        text-align: center;
        background: #fff;
        &.selected {
          color: #FF0000;
          .span {
            position: absolute;
            top:0;
            right: 0;
            width: 14px;
            height: 14px;
            line-height: 16px;
            padding-right: 2px;
            font-size: 8px;
            text-align: right;
            border-bottom-left-radius: 100px;
            color: #fff;
            box-sizing: border-box;
            background: #FF0000;
          }
        }
      }
    }
  }
  .product-list {
    padding-top: 15px;
    .product-vant-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 160px;
        padding: 0 15px;
        margin-bottom: 15px;
        text-align: center;
        border: 1px solid #1A1A1A;
        background: #111111;
        box-sizing: border-box;
        .pic {
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
        .more {
          padding: 10px 0;
          font-size: 8px;
          border-bottom: 1px solid #1A1A1A
        }
        .title {
          padding: 15px 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
